import React, { useCallback, useState, useEffect } from "react";
import classes from "./NavigationItems.module.css";

import List from "@mui/material/List";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArticleIcon from '@mui/icons-material/Article';
import NavigationItem from "../NavigationItem/NavigationItem";
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { useSelector } from 'react-redux';
import { appName, getUserInfo } from "../../../assets/shared/sessionData";

const NavigationItems = (props) => {
  const isAuth = useSelector(state => state.auth.token !== null);
  const userData = useSelector(state => state.auth.userInfo);

  const user = useSelector(state => state.auth.userInfo);
  const { gauss } = user;
  const [userInfo, setUserInfo] = useState();
  const [secciones, setSecciones] = useState([]);

  const obtenerSecciones = useCallback((userInfo) => {
    const items = [];

    if (userInfo !== null && userInfo !== undefined
      && userInfo.perfil !== null && userInfo.perfil !== undefined
      && userInfo.perfil.perfilAcceso !== null && userInfo.perfil.perfilAcceso !== undefined) {
      const perfilesAccesos = userInfo.perfil.perfilAcceso;
      const filtrados = perfilesAccesos.filter((pa) => pa.aplicacionKey === appName());
      if (filtrados)
        filtrados.forEach((fil) => items.push(fil.key));

    }
    setSecciones(items);
  }, []);

  useEffect(() => {
    if (userInfo === null || userInfo === undefined || userInfo.id === null || userInfo.id === undefined) {
      const item = userData !== null && userData !== undefined
        && userData.id !== null && userData.id !== undefined
        ? userData
        : getUserInfo();
      setUserInfo(item);
      obtenerSecciones(item);
    }
  }, [userData, userInfo, setUserInfo, obtenerSecciones]);

  const tieneAcceso = (key) => {
    const seccion = secciones.find((sec) => sec === key);
    return gauss || (seccion !== null && seccion !== undefined);
  }

  return (
    <List className={classes.NavigationItems}>
      {isAuth && (
        <React.Fragment>
          {tieneAcceso('administracionAlertas') && (
            <NavigationItem link="/administracionAlertas" title="Administración de Alertas"
              onClickItem={props.onClickItem}>
              <NotificationsIcon className={classes.NavigationItemIcon} />
            </NavigationItem>
          )}
          {tieneAcceso('administracionInformes') && (
            <NavigationItem link="/administracionInformes" title="Administración de Informes"
              onClickItem={props.onClickItem}>
              <ArticleIcon className={classes.NavigationItemIcon} />
            </NavigationItem>
          )}
          {tieneAcceso('probarNotificaciones') && (
            <NavigationItem link="/pruebaNotificaciones" title="Probar Notificaciones"
              onClickItem={props.onClickItem}>
              <EditNotificationsIcon className={classes.NavigationItemIcon} />
            </NavigationItem>
          )}
        </React.Fragment>
      )}
    </List>
  );
};

export default NavigationItems;