import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import classes from './AdministracionAlertas.module.css';
import ConteinerWithEjecutarPagina from "../../components/UI/ConteinerWithEjecutarPagina/ConteinerWithEjecutarPagina";
import FullWidthTabs from "../../components/UI/FullWidthTabs/FullWidthTabs";
import AlertaConfiguradaList from "../../components/UI/Alertas/AlertasConfiguradas/AlertaConfiguradaList";
import { loadEmpresasDelUsuario } from "../../store/slices/multiempresaSlice";
import { storageClienteId, storageUsuarioId } from "../../assets/shared/sessionData";
import { loadUsuarios } from "../../store/slices/clientesSlice";
import TipoAlertasList from "../../components/UI/Alertas/TipoAlertas/TipoAlertasList";
import { Grid } from "@mui/material";

const AdministracionAlertas = () => {
  const dispatch = useDispatch();
  sessionStorage.setItem('seccionNombre', 'Administración Alertas');
  const ejecucionDePagina = useSelector(state => state.ejecucionPaginas.ejecucionAdministracionAlertas);
  const loadingEmpresas = useSelector(state => state.multiempresa.empresasDelUsuario.loading);
  const estaCargadoEmpresas = useSelector(state => state.multiempresa.empresasDelUsuario.estaCargado);
  const loadingUsuarios = useSelector(state => state.clientes.usuarios.loading);
  const estaCargadoUsuarios = useSelector(state => state.clientes.usuarios.estaCargado);

  useEffect(() => {
    if (!loadingEmpresas && !estaCargadoEmpresas)
      dispatch(loadEmpresasDelUsuario(storageUsuarioId()));
  }, [loadingEmpresas, estaCargadoEmpresas, dispatch]);

  useEffect(() => {
    if (!loadingUsuarios && !estaCargadoUsuarios)
      dispatch(loadUsuarios(storageClienteId()));
  }, [loadingUsuarios, estaCargadoUsuarios, dispatch]);

  return (
    <ConteinerWithEjecutarPagina isExecuted={!ejecucionDePagina} >
      <FullWidthTabs
        tabs={['Configuradas', 'Disponibles']}
        views={[
          <AlertaConfiguradaList ejecucionDePagina={ejecucionDePagina} />,
          <TipoAlertasList ejecucionDePagina={ejecucionDePagina} />
        ]}
        rootClass={classes.TabsRoot}
      />
    </ConteinerWithEjecutarPagina>
  );
};

export default AdministracionAlertas;