import React, { Fragment, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import classes from './Informes.module.css';
import { loadInformesConfigurados, loadConfiguracionesDefault } from "../../../../store/slices/informesSlice";
import { storageUsuarioId } from "../../../../assets/shared/sessionData";
import Spinner from "../../Spinner/Spinner";
import InformeConfiguradoCard from "./InformeConfiguradoCard";
import Dialog from "../../Dialog/Dialog";
import { closeInformeModal } from "../../../../store/slices/informesSlice";
import { Close } from "@mui/icons-material";
import InformeConfiguradoDetalle from "./InformeConfiguradoDetalle";
import InformeConfiguradoEjecuciones from "./InformeConfiguradoEjecuciones";
import InformeConfiguradoEjecutar from "./InformeConfiguradoEjecutar";
import InformeConfiguradoEliminar from "./InformeConfiguradoEliminar";

const InformeConfiguradoList = (props) => {
  //#region Dispatches
  const dispatch = useDispatch();

  const loading = useSelector(state => state.informes.informesConfigurados.loading);
  const estaCargado = useSelector(state => state.informes.informesConfigurados.estaCargado);
  const datos = useSelector(state => state.informes.informesConfigurados.informes);
  const dialogState = useSelector(state => state.informes.informeModal);

  const empresasDelUsuario = useSelector(state => state.multiempresa.empresasDelUsuario.empresas);
  const usuariosDelCliente = useSelector(state => state.clientes.usuarios.usuarios);
  //#endregion

  const showModalContent = () => {
    if (dialogState.type === 'copy')
      return showModalCopyInforme(dialogState.informe);

    if (dialogState.type === 'update')
      return showModalUpdateInforme(dialogState.informe);

    if (dialogState.type === 'delete')
      return showModalDeleteInforme(dialogState.informe);

    if (dialogState.type === 'execute')
      return showModalExecuteInforme(dialogState.informe);

    if (dialogState.type === 'history')
      return showModalHistoryInforme(dialogState.informe);
  }

  const showModalCopyInforme = (informe) => {
    var copiaInforme = { ...informe };

    copiaInforme.id = 0;
    copiaInforme.tituloInforme = `${informe.tituloInforme} - Copia`;

    if (!copiaInforme.usuario) {
      var usuario = usuariosDelCliente.find((usu) => usu.id === storageUsuarioId());

      if (!usuario) {
        usuario = {
          id: storageUsuarioId()
        }
      }

      copiaInforme.usuario = usuario;
    }

    dispatch(loadConfiguracionesDefault(informe.id));

    return (
      <InformeConfiguradoDetalle
        informe={copiaInforme}
        empresas={empresasDelUsuario}
        usuarios={usuariosDelCliente}
        canEdit={true}
      />
    )
  }

  const showModalUpdateInforme = (informe) => {
    dispatch(loadConfiguracionesDefault(informe.id));

    return (
      <InformeConfiguradoDetalle
        informe={informe}
        informeId={informe.id}
        empresas={empresasDelUsuario}
        usuarios={usuariosDelCliente}
        canEdit={true}
      />
    )
  }

  const showModalHistoryInforme = (informe) => {
    return (
      <InformeConfiguradoEjecuciones informe={informe} />
    )
  }

  const showModalExecuteInforme = (informe) => {
    return (
      <InformeConfiguradoEjecutar informe={informe} />
    )
  }

  const showModalDeleteInforme = (informe) => {
    return (
      <InformeConfiguradoEliminar informe={informe} />
    )
  }

  useEffect(() => {
    if (!estaCargado && !loading)
      dispatch(loadInformesConfigurados(storageUsuarioId()));
  }, [estaCargado, loading, dispatch]);

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.PanelContainer} display='flex' flexDirection='column'>
        <Grid item xs={12}>
          <Typography variant="h5">
            Informes Configuradas
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} direction='row' justifyContent='flex-start'>
          {loading ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Spinner />
            </div>
          ) : (
            datos && datos.map((informe, index) => (
              <InformeConfiguradoCard
                key={index}
                index={index}
                informe={informe}
              />
            ))
          )}
        </Grid>
      </Grid>
      <Dialog
        title={(
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">{dialogState.title}</Typography>
              <IconButton aria-label="close" onClick={() => dispatch(closeInformeModal())} >
                <Close />
              </IconButton>
            </div>
            {dialogState && dialogState.subTitle && dialogState.subTitle !== ''
              && (<div className={classes.ModalSubtitleTexto}>
                <Typography variant="caption">
                  {dialogState.subTitle}
                </Typography>
              </div>
              )}
          </div>
        )}
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: dialogState.type === 'history'
            ? '10px 0px 0px'
            : undefined
        }}
        maxWidth={dialogState.maxWidth ? dialogState.maxWidth : 'md'}
        show={dialogState.show}
        body={
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            {showModalContent()}
          </div>
        }
        onClose={() => dispatch(closeInformeModal())}
      />
    </Fragment>
  )
}

export default InformeConfiguradoList;