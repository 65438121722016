import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Grid, List, IconButton, Typography, Tooltip, FormControl, InputLabel, Select, MenuItem, Button, Divider, FormHelperText } from "@mui/material";
import { customCheckbox, customControls, customInput, customSelect } from '../../../../assets/shared/controls';
import { checkValidity } from '../../../../assets/shared/utility';
import { AddOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import classes from './Alertas.module.css';
import Dialog from "../../Dialog/Dialog";
import { Close } from "@mui/icons-material";
import Spinner from '../../Spinner/Spinner';
import { actualizarAlerta, closeAlertaModal, crearAlerta, loadAlertasConfiguradas, loadConfiguracionesDefault, 
  loadConfiguracionesDefaultPorTipo, loadDetalleAlerta } from '../../../../store/slices/alertasSlice';
import { enqueueSnackbar } from '../../../../store/slices/snackbarSlice';
import { storageUsuarioId } from '../../../../assets/shared/sessionData';
import { loadAlertasDisponibles } from '../../../../store/slices/tipoAlertaSlice';

const EmailContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const AlertaConfiguradaDetalle = (props) => {
  const dispatch = useDispatch();
  const { alerta, alertaId, canEdit, empresas, usuarios } = props;

  const { loading } = useSelector((state) => state.alertas.detalleAlerta);
  const alertaDetails = useSelector((state) => state.alertas.detalleAlerta.alerta);

  const [formIsValid, setFormIsValid] = useState(true);
  const [show, setShow] = useState(false);
  const [configuraciones, setConfiguraciones] = useState([]);
  const usuariosApp = usuarios && usuarios.filter((u) => u.accesoApp);
  const usuariosSelect = useMemo(() => {
    return usuarios && usuarios.map((user) => {
      return {
        id: user.id,
        nombre: `${user.nombre} ${user.apellido}`
      }
    })
  }, [usuarios]);

  const styleSwitch = {
    width: '100%',
    marginLeft: '0px',
    marginRight: '20px',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }

  const inputLabelProps = {
    style: {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)',
      width: '100%',
    }
  }

  const [destinatariosEmail, setDestinatariosEmail] = useState(alerta.destinatariosEmail);
  const [destinatariosApp, setDestinatariosApp] = useState(alerta.destinatariosApp);
  const [controls, setControls] = useState({
    tituloAlerta: {
      name: "tituloAlerta",
      label: "Título Alerta *",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.tituloAlerta : '',
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      errorMessage: "Campo requerido",
    },
    descripcionAlerta: {
      name: "descripcionAlerta",
      label: "Descripción Alerta",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.descripcionAlerta : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    },
    responsable: {
      name: "usuario",
      label: "Responsable de contacto",
      placeholder: "",
      elementType: "select",
      elementConfig: {
        type: "select",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.usuario : '',
      validation: {
        required: true,
      },
      valid: true,
      required: true,
      touched: false,
      errorMessage: "Campo requerido",
      items: usuariosSelect
    },
    empresa: {
      name: "empresa",
      label: "Empresa",
      placeholder: "",
      elementType: "select",
      elementConfig: {
        type: "select",
        inputLabelProps
      },
      disabled: !canEdit,
      value: alerta ? alerta.empresa : '',
      validation: {
        required: true,
      },
      valid: true,
      required: true,
      touched: false,
      errorMessage: "Campo requerido",
      items: empresas
    },
    notificarPorMail: {
      elementType: "switch",
      name: "notificarEmail",
      label: "Notificarme por mail",
      elementConfig: {
        type: "switch",
      },
      validation: {},
      disabled: !canEdit,
      value: alerta.notificarPorMail,
      valid: true,
      touched: false,
      errorMessage: "",
      style: styleSwitch,
      labelPlacement: 'top'
    },
    habilitada: {
      elementType: "switch",
      name: "habilitada",
      label: "Habilitada",
      elementConfig: {
        type: "switch",
      },
      validation: {},
      disabled: !canEdit,
      value: alerta.habilitada,
      valid: true,
      touched: false,
      errorMessage: "",
      style: styleSwitch,
      labelPlacement: 'top'
    },
  });

  const destIsValid = (destinatariosEmail && destinatariosEmail.length > 0) || (destinatariosApp && destinatariosApp.length > 0);

  const validateForm = (updatedControls, isValid) => {
    let formIsValid = isValid;
    for (let formElementKey in updatedControls) {
      if (updatedControls[formElementKey].validation) {
        const [updatedControlValid, updatedControlErrorMessage] = checkValidity(updatedControls[formElementKey].value, updatedControls[formElementKey].validation);
        formIsValid = formIsValid && updatedControlValid;

        if (!updatedControlValid) {
          updatedControls[formElementKey].valid = updatedControlValid;
          updatedControls[formElementKey].errorMessage = updatedControlErrorMessage;
          updatedControls[formElementKey].touched = true;
        }
      }
    }

    formIsValid = formIsValid && destIsValid;

    setControls(updatedControls);

    return formIsValid;
  }

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };

    const value = updatedControl.elementType === "checkbox"
      ? event.target.checked
      : updatedControl.elementType === "select"
        ? event
        : updatedControl.elementType === "switch"
          ? !updatedControl.value
          : event.target.value;

    if (updatedControl.elementType === 'file') {
      updatedControl.file = event.target.files[0];
      updatedControl.value = event.target.files[0].name;
    } else {
      updatedControl.value = value;
    }

    const [isValid, errorMessage] = checkValidity(value, controls[inputId].validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    setFormIsValid(validateForm(updatedControls, isValid));
  };

  let form = customControls(controls, inputValueChangedHandler);

  const onEditarConfiguracionClick = () => {
    setShow(true);
  }

  const onUpdateConfigurationes = (items) => {
    setConfiguraciones(items);
    setShow(false);
  }

  const getDestinatarios = (destinatariosEmail, destinatariosApp) => {
    var notificacionMediosDeContacto = [];

    if (destinatariosEmail && destinatariosEmail.length > 0) {
      destinatariosEmail.forEach((dest) => {
        notificacionMediosDeContacto.push({
          usuarioId: dest.id,
          enviarNotificacionMail: true,
          enviarNotificacionApp: false
        });
      });
    }

    if (notificacionMediosDeContacto && notificacionMediosDeContacto.length > 0) {
      notificacionMediosDeContacto.forEach((notif) => {
        var destApp = destinatariosApp && destinatariosApp.find((dest) => dest.id === notif.usuarioId);

        if (destApp)
          notif.enviarNotificacionApp = true;
      });
    }

    if (destinatariosApp && destinatariosApp.length > 0) {
      destinatariosApp.forEach((dest) => {
        var destEmail = notificacionMediosDeContacto.find((notif) => notif.usuarioId === dest.id);

        if (!destEmail) {
          notificacionMediosDeContacto.push({
            usuarioId: dest.id,
            enviarNotificacionMail: false,
            enviarNotificacionApp: true
          });
        }
      });
    }

    return notificacionMediosDeContacto;
  }

  const getConfiguracionesDefault = (configuraciones) => {
    var configuracionesDefault = [];

    configuraciones && configuraciones.forEach((config) => {
      var configDefault = {
        idConfiguracion: config.idConfiguracion,
        valor: config.valor
      };
      configuracionesDefault.push(configDefault);
    });

    return configuracionesDefault;
  }

  const onCrearAlerta = (alerta) => {
    let tempAlerta = { ...alerta };

    tempAlerta.tituloAlerta = controls.tituloAlerta.value;
    tempAlerta.descripcionAlerta = controls.descripcionAlerta.value;
    tempAlerta.usuarioId = controls.responsable.value ? controls.responsable.value.id : "";
    tempAlerta.idEmpresa = controls.empresa.value ? controls.empresa.value.id : 0;
    tempAlerta.notificarPorMail = controls.notificarPorMail.value;
    tempAlerta.habilitada = controls.habilitada.value;
    tempAlerta.configuracionesDefault = getConfiguracionesDefault(configuraciones);

    var notificacionMediosDeContacto = getDestinatarios(destinatariosEmail, destinatariosApp);
    tempAlerta.notificacionMediosDeContacto = notificacionMediosDeContacto;

    const onCrearAlertaSuccess = () => {
      dispatch(loadAlertasConfiguradas(storageUsuarioId()));
      dispatch(loadAlertasDisponibles(storageUsuarioId()));
      dispatch(closeAlertaModal());
      dispatch(enqueueSnackbar({
        message: 'Alerta generada con éxito!',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      }));
    }

    const onCrearAlertaError = () => {
      dispatch(enqueueSnackbar({
        message: "Error al crear la alerta.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
      );
    }

    dispatch(crearAlerta({ alerta: tempAlerta, onSuccess: onCrearAlertaSuccess, onError: onCrearAlertaError }));
  }

  const onUpdateAlerta = (alerta) => {
    var notificacionMediosDeContacto = getDestinatarios(destinatariosEmail, destinatariosApp);
    var configuracionesDefault = getConfiguracionesDefault(configuraciones);
    let tempAlerta = {
      id: alerta.id,
      idTipoAlerta: alerta.tipoAlerta ? alerta.tipoAlerta.id : alerta.idTipoAlerta,
      idEmpresa: controls.empresa.value ? controls.empresa.value.id : 0,
      notificacionMediosDeContacto,
      tituloAlerta: controls.tituloAlerta.value,
      descripcionAlerta: controls.descripcionAlerta.value,
      habilitada: controls.habilitada.value,
      usuarioId: controls.responsable.value ? controls.responsable.value.id : "",
      configuracionesDefault
    };

    const onActualizarAlertaSuccess = () => {
      dispatch(loadAlertasConfiguradas(storageUsuarioId()));
      dispatch(loadAlertasDisponibles(storageUsuarioId()));
      dispatch(closeAlertaModal());
      dispatch(enqueueSnackbar({
        message: 'Alerta actualizada con éxito!',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      }));
    }

    const onActualizarAlertaError = () => {
      dispatch(enqueueSnackbar({
        message: "Error al actualizar la alerta.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
      );
    }

    dispatch(actualizarAlerta({ alerta: tempAlerta, onSuccess: onActualizarAlertaSuccess, onError: onActualizarAlertaError }));
  }

  const onGuardarCambiosClick = () => {
    let isValid = true;
    const updatedControls = { ...controls };
    isValid = validateForm(updatedControls, isValid);

    if (isValid) {
      if (alerta.id === 0)
        onCrearAlerta(alerta);
      else
        onUpdateAlerta(alerta);
    }
  }

  useEffect(() => {
    if ((alertaId && (!alertaDetails || alertaId !== alertaDetails.id)) && !loading) {
      dispatch(loadDetalleAlerta(alertaId));
    }
  }, [alertaId, alertaDetails, loading, dispatch]);

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={6}>
            {form[0]}
          </Grid>
          <Grid item xs={6}>
            {form[1]}
          </Grid>
          <Grid item xs={6} lg={3} style={{ paddingTop: '0px' }}>
            {form[2]}
          </Grid>
          <Grid item xs={6} lg={3} style={{ paddingTop: '0px' }}>
            {form[3]}
          </Grid>
          <Grid item xs={6} lg={3}>
            {form[4]}
          </Grid>
          <Grid item xs={6} lg={3}>
            {form[5]}
          </Grid>
          <Grid item xs={12} lg={6} container spacing={1}>
            <AlertaDestinatariosApp
              title="Destinatarios Email"
              destinatarios={destinatariosEmail}
              setDestinatarios={setDestinatariosEmail}
              usuarios={usuarios}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={12} lg={6} container spacing={1}>
            <AlertaDestinatariosApp
              title="Destinatarios App"
              destinatarios={destinatariosApp}
              setDestinatarios={setDestinatariosApp}
              usuarios={usuariosApp}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid item xs={12} container spacing={1} justifyContent='flex-end' alignContent='center'>
          <Grid item xs={6} lg={8}>
            <Typography component='div' style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: 'small' }}>Seleccione una empresa antes de editar las configuraciones</span>
              {!destIsValid && (<span style={{ fontSize: 'small', color: 'red' }}>Agregue al menos un destinatario de Email o App</span>)}
            </Typography>
          </Grid>
          <Grid item xs={3} lg={2}>
            <Button
              disabled={!canEdit || !controls.empresa.value}
              onClick={() => onEditarConfiguracionClick()}
              color='info'
              variant='contained'
              fullWidth={true}
              style={{ paddingInline: '15px', color: 'white' }} >
              Configurar
            </Button>
          </Grid>
          <Grid item xs={3} lg={2}>
            <Button
              disabled={!canEdit || !formIsValid}
              onClick={() => onGuardarCambiosClick()}
              color='primary'
              variant='contained'
              fullWidth={true}
              style={{ paddingInline: '15px', color: 'white' }} >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        title={(
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">Configuraciones</Typography>
              <IconButton aria-label="close" onClick={() => setShow(false)} >
                <Close />
              </IconButton>
            </div>
          </div>
        )}
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: '20px 40px 20px 40px'
        }}
        maxWidth='lg'
        show={show}
        body={(
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            <AlertaConfiguraciones
              alerta={alerta}
              canEdit={canEdit}
              updateConfiguraciones={onUpdateConfigurationes}
              idEmpresa={controls.empresa && controls.empresa.value && controls.empresa.value.id}
            />
          </div>
        )}
        onClose={() => setShow(false)}
      />
    </Fragment>
  )
}

const AlertaDestinatariosApp = (props) => {
  const { title, destinatarios, setDestinatarios, usuarios, disabled } = props;

  const [newDestinatario, setNewDestinatario] = useState('');
  const [newDestinatarioError, setNewDestinatarioError] = useState(false);
  const [newDestinatarioErrorMessage, setNewDestinatarioErrorMessage] = useState('Campo requerido');
  const [addDestinatario, setAddDestinatario] = useState(false);

  const onConfirmAddDestinatario = () => {
    if (newDestinatario !== '') {
      setNewDestinatarioError(false);
      const usuarioExistente = destinatarios.find((d) => d.id === newDestinatario);

      if (usuarioExistente) {
        setNewDestinatarioError(true);
        setNewDestinatarioErrorMessage('Usuario existente');
        return;
      }
      debugger;
      const selectedUser = usuarios.find((u) => u.id === newDestinatario);

      if (selectedUser) {
        let tempDestinatarios = [].concat(destinatarios);
        tempDestinatarios.push(selectedUser);
        setDestinatarios(tempDestinatarios);
        clearAddDestinatario();
      }
      setAddDestinatario(false);
    }
    else {
      setNewDestinatarioError(true);
    }
  }

  const onCancelAddDestinatario = () => {
    clearAddDestinatario();
    setAddDestinatario(false);
  }

  const handleDelete = (usuario) => {
    if (disabled)
      return;

    const filterDestinatarios = destinatarios.filter((u) => u.id !== usuario.id);

    if (filterDestinatarios)
      setDestinatarios(filterDestinatarios);
  }

  const clearAddDestinatario = () => {
    setNewDestinatario('');
    setNewDestinatarioError(false);
    setNewDestinatarioErrorMessage('Campo requerido');
  }

  return (
    <Fragment>
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="h6">{title}</Typography>
        <Tooltip title="Agregar destinatario">
          <IconButton
            style={{ width: '25px', height: '25px' }}
            onClick={() => disabled ? undefined : setAddDestinatario(true)}
          >
            <AddOutlined />
          </IconButton>
        </Tooltip>
      </Grid>
      {
        addDestinatario && (
          <Grid item xs={12} container spacing={1} justifyContent='space-between' alignContent='center' style={{ marginBottom: '10px' }}>
            <Grid item xs={6}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <InputLabel id="Add-Destinatario">Usuario *</InputLabel>
                <Select
                  labelId="Add-Destinatario"
                  id="Add-Destinatario"
                  value={newDestinatario}
                  onChange={(event) => setNewDestinatario(event.target.value)}
                  required={true}
                  style={{ fontSize: 'small' }}
                  error={newDestinatarioError}
                >
                  {usuarios && usuarios.map((user, index) => (
                    <MenuItem
                      key={index}
                      value={user.id}
                      style={{ fontSize: 'small' }}>
                      {user.nombre + ' ' + user.apellido}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={newDestinatarioError} sx={{ color: 'red' }}>{newDestinatarioErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Tooltip title="Confirmar">
                <IconButton onClick={onConfirmAddDestinatario}>
                  <CheckOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar">
                <IconButton onClick={onCancelAddDestinatario}>
                  <CloseOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <EmailContainer>
          <List dense={true} style={{ paddingTop: '0px' }}>
            {destinatarios.map((usuario, index) => (
              <Chip
                key={index}
                label={usuario.nombre + ' ' + usuario.apellido}
                variant="outlined"
                onDelete={() => handleDelete(usuario)}
                style={{ margin: '1px' }}
              />
            ))}
          </List>
        </EmailContainer>
      </Grid>
    </Fragment>
  )
}

const AlertaConfiguraciones = (props) => {
  const dispath = useDispatch();
  const { alerta, canEdit, idEmpresa, updateConfiguraciones } = props;
  const [configuraciones, setConfiguraciones] = useState();
  const itemConfiguraciones = useSelector((state) => state.alertas.configuraciones.items);
  const loadingConfiguraciones = useSelector((state) => state.alertas.configuraciones.loading);
  const estaCargadoConfiguraciones = useSelector((state) => state.alertas.configuraciones.estaCargado);

  useEffect(() => {
    if (!loadingConfiguraciones && !configuraciones && alerta.id > 0) {
      dispath(loadConfiguracionesDefault(alerta.id));
    }
  }, [loadingConfiguraciones, configuraciones, alerta, dispath]);

  useEffect(() => {
    if (!loadingConfiguraciones && !configuraciones && alerta.id === 0 && idEmpresa) {
      dispath(loadConfiguracionesDefaultPorTipo({ idTipoAlerta: alerta.idTipoAlerta, idEmpresa }));
    }
  }, [loadingConfiguraciones, configuraciones, alerta, idEmpresa, dispath]);

  useEffect(() => {
    if (!loadingConfiguraciones && estaCargadoConfiguraciones) {
      if (!configuraciones)
        setConfiguraciones(itemConfiguraciones);
    }
  }, [loadingConfiguraciones, estaCargadoConfiguraciones, configuraciones, itemConfiguraciones]);

  const inputLabelProps = {
    style: {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)',
      width: '100%',
    }
  }

  const onUpdateConfiguration = (config) => {
    var tempConfigs = [];
    configuraciones.forEach((c) => {
      var tempConfig = { ...c };
      if ((tempConfig.id === 0 && tempConfig.idConfiguracion === config.idConfiguracion) || (tempConfig.id !== 0 && tempConfig.id === config.id)) {
        tempConfig.valor = config.valor;
      }

      tempConfigs.push(tempConfig);
    });

    setConfiguraciones(tempConfigs);
  }

  const renderConfiguracion = (config) => {

    var tempCampo = (config.campo ? config.campo : config.display.trim());
    var campo = tempCampo.split(".").length > 1
      ? tempCampo.split(".")[1]
      : tempCampo.split(".")[0];

    let elem = {
      name: campo,
      label: config.display,
      placeholder: config.display,
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: false,
      value: config.valor ? config.valor : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    };

    const onChangeHandler = (event, inputId) => {
      let value = elem.elementType === "checkbox"
        ? event.target.checked
        : elem.elementType === "select"
          ? event
          : elem.elementType === "switch"
            ? !elem.value
            : event.target.value;

      if (config.tipoUI === "Dropdown") {
        value = value[campo];
      }

      if (config.tipoUI === "Dropdown Multiselect") {
        if (value && value.length > 0) {
          let tempValue = `("${value.map((item) => item[campo]).join()}")`;
          value = tempValue;
        }
      }

      elem.value = value;

      var tempConfig = { ...config };
      tempConfig.valor = value;

      onUpdateConfiguration(tempConfig);
    }

    if (config.tipoUI === "TextBox") {
      return customInput(elem, onChangeHandler);
    } else if (config.tipoUI === "CheckBox") {
      elem.elementConfig.type = "checkbox";
      return customCheckbox(elem, onChangeHandler);
    } else if (config.tipoUI === "Dropdown") {
      elem.elementType = 'select';
      elem.elementConfig.type = 'select';
      elem.multiple = false;

      var value = config.valor;

      if (typeof config.valor === 'string') {
        var tempValue = value.trimEnd();

        if (config.data) {
          config.data.forEach((item) => {
            var itemValue = item[campo];
            if (itemValue && itemValue.trimEnd() === tempValue)
              value = item;
          });
        }
      }

      elem.value = value;
      elem.items = config.data;
      return customSelect(elem, onChangeHandler);
    } else if (config.tipoUI === "DateTime") {
      elem.elementConfig.type = "datetime";
      return customInput(elem, onChangeHandler);
    } else if (config.tipoUI === "TextBox number") {
      elem.elementConfig.type = "number";
      return customInput(elem, onChangeHandler);
    } else if (config.tipoUI === "Dropdown Multiselect") {
      elem.elementType = 'select';
      elem.elementConfig.type = 'select';
      elem.multiple = true;

      var multiSelectValue = [];
      if (config.valor && typeof config.valor === 'string' && config.valor !== "") {
        var stringValue = config.valor;
        stringValue = stringValue.replace('("', "");
        stringValue = stringValue.replace('")', "");

        if (stringValue && stringValue !== "" && config.data && config.data.length > 0) {
          stringValue.split(",").forEach((stringItem) => {
            config.data.forEach((item) => {
              var itemValue = item[campo];
              if (itemValue && itemValue.trimEnd() === stringItem)
                multiSelectValue.push(item);
            });
          })
        }
      }

      elem.value = multiSelectValue;
      elem.items = config.data;
      return customSelect(elem, onChangeHandler);
    } else {
      return customInput(elem, onChangeHandler);
    }
  }

  const onGuardarCambiosClick = () => {
    updateConfiguraciones(configuraciones);
  }

  return loadingConfiguraciones
    ? (
      <Spinner />
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={12} container spacing={1} sx={{ minHeight: '220px', width: '550px' }}>
          {configuraciones && configuraciones.map((config, index) => (
            <Grid item xs={config.tipoUI === "Dropdown Multiselect" ? 12 : 4} key={index}>
              {renderConfiguracion(config)}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='flex-end' alignContent='center'>
          <Grid item xs={6} lg={3}>
            <Button disabled={!canEdit || !configuraciones || configuraciones.length === 0}
              onClick={() => onGuardarCambiosClick()}
              color='primary' variant='contained'
              style={{ paddingInline: '15px', color: 'white' }} >
              Guardar cambios
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
}

export default AlertaConfiguradaDetalle;