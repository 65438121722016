import React, { useRef } from 'react';
import classes from './Input.module.css';
import TextField from '@mui/material/TextField';

const Input = (props) => {
  let validationError = null;
  if (props.invalid && props.touched) {
    validationError = props.errorMessage;
  }

  const inputRef = useRef();

  let elementId = "";
  let elementType = "";

  switch (props.elementConfig.type) {
    case "password": {
      elementType = "password";
      elementId = props.name;

      break;
    }

    default: {
      elementId = props.name;
      elementType = props.elementConfig.type;
    }
  }

  return (
    <div style={{ alignSelf: props.alignSelf }}>
      <TextField
        ref={inputRef}
        className={classes.InputElement}
        style={props.style}
        id={elementId}
        type={elementType}
        disabled={props.disabled}
        required={props.required}
        placeholder={props.placeholder}
        value={props.value}
        label={props.label ? props.label : props.elementConfig.placeholder}
        error={validationError ? true : false}
        helperText={validationError}
        onChange={props.valueChanged}
        autoComplete={props.autoComplete}
        variant={props.variant ? props.variant : 'standard'}
        InputLabelProps={
          props.elementConfig.type === "date" || props.elementConfig.type === "time"
            ? {
                shrink: true,
              }
            : props.elementConfig.inputLabelProps 
              ? props.elementConfig.inputLabelProps 
              : undefined
        }
        InputProps={{
          startAdornment: props.startAdornment,
          endAdornment: props.endAdornment,
        }}
      />
    </div>
  );
};

export default Input;
