import React from "react";
import classes from "./controls.module.css";
import Input from "../../components/UI/Input/Input";
import { Autocomplete, FormControl, FormControlLabel, TextField, Checkbox, Switch, Button } from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

export const customInput = (elem, onChangeHandler) => {

  return (
    <Input
      key={elem.id}
      name={elem.name}
      label={elem.label}
      placeholder={elem.placeholder}
      elementType={elem.elementType}
      elementConfig={elem.elementConfig}
      value={elem.value}
      valueChanged={(event) => onChangeHandler(event, elem.id)}
      invalid={!elem.valid}
      shouldValidate={elem.validation}
      touched={elem.touched}
      errorMessage={elem.errorMessage}
      disabled={elem.disabled}
      style={elem.elementStyle}
      required={elem.required}
      endAdornment={elem.endAdornment}
    />
  )
}

export const customCheckbox = (elem, onChangeHandler) => {
  return (
    <FormControlLabel
      key={elem.id}
      style={elem.style}
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" />}
          checked={elem.value}
          onChange={(event) => onChangeHandler(event, elem.id)}
          name={elem.name}
          disabled={elem.disabled}
        />
      }
      label={elem.label}
      classes={{
        label: classes.checkboxLabel,
      }}
    />
  )
}

export const customSwitch = (elem, onChangeHandler) => {
  return (
    <FormControlLabel
      key={elem.id}
      style={elem.style}
      control={
        <Switch
          defaultChecked={elem.value}
          onChange={(event) => onChangeHandler(event, elem.id)}
          disabled={elem.disabled}
        />
      }
      label={elem.label}
      labelPlacement={elem.labelPlacement}
      classes={{
        label: classes.switchLabel,
      }}
      disabled={elem.disabled}
    />
  )
}

export const customSelect = (elem, onChangeHandler) => {
  const description = `Seleccione ${elem.label}`;
  let value = elem.value;

  const onAutocompleteValueChange = (event, newValue) => {
    onChangeHandler(newValue, elem.id);
    value = newValue;
  }

  let validationError = null;
  if (!elem.valid && elem.touched) {
    validationError = elem.errorMessage;
  }

  return (
    <FormControl className={classes.autocompleteRootControl} >
      <Autocomplete
        classes={{
          root: classes.autocompleteRoot,
          input: classes.autocompleteInput,
          option: classes.autocompleteOptions,
        }}
        autoComplete={true}
        options={elem.items}
        getOptionLabel={(option) => option.nombre ? option.nombre.toUpperCase().trimEnd() : ""}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.autocompleteInput}
            label={elem.label}
            placeholder={description.toUpperCase()}
            margin="normal"
            variant="standard"
            required={elem.required}
            error={validationError ? true : false}
            helperText={validationError}
            InputLabelProps={elem.elementConfig.inputLabelProps ? elem.elementConfig.inputLabelProps : undefined}
          />)
        }
        disabled={elem.disabled}
        value={value}
        onChange={(event, newValue) => onAutocompleteValueChange(event, newValue)}
        size="small"
        style={{ marginBottom: '0px' }}
        multiple={elem.multiple}
      />
    </FormControl>
  )
}

export const customFileUpload = (elem, onChangeHandler) => {
  return (
    <form>
      <Input
        id={elem.id}
        key={elem.id}
        name={elem.name}
        elementType={elem.elementType}
        elementConfig={elem.elementConfig}
        valueChanged={(event) => onChangeHandler(event, elem.id)}
        style={{ display: 'none' }}
      />
      <Input
        label={elem.label}
        placeholder={elem.placeholder}
        elementType="input"
        elementConfig={{
          type: "input",
          inputLabelProps: elem.elementConfig.inputLabelProps
        }}
        value={elem.value}
        invalid={!elem.valid}
        shouldValidate={elem.validation}
        touched={elem.touched}
        errorMessage={elem.errorMessage}
        disabled={elem.disabled}
        required={elem.required}
        startAdornment={
          <label htmlFor={elem.id}>
            <Button variant="outlined" component="span" size="small" disabled={elem.disabled}>
              Adjuntar
            </Button>
          </label>

        }
      />
    </form>
  );
}

export const customControls = (controls, onChangeHandler) => {
  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({ id: key, ...controls[key] });
  }

  let form = formElementsArray.map((elem) => {
    if (elem.elementType === "checkbox") {
      return customCheckbox(elem, onChangeHandler);
    } else if (elem.elementType === "select") {
      return customSelect(elem, onChangeHandler);
    } else if (elem.elementType === "switch") {
      return customSwitch(elem, onChangeHandler);
    } else if (elem.elementType === "file")
      return customFileUpload(elem, onChangeHandler);
    else {
      return customInput(elem, onChangeHandler);
    }
  });

  return form;
}

export const getCheckboxConfig = (name, label, value = false, disabled = false, style = null) => {
  const config = {
    elementType: "checkbox",
    name: name,
    label: label,
    elementConfig: {
      type: "checkbox",
    },
    validation: {},
    disabled: disabled,
    value: value,
    valid: true,
    touched: false,
    errorMessage: "",
    style: style
  };

  return config;
}

export const getSwitchConfig = (name, label, value = false, disabled = false, style = null, labelPlacement = "top") => {
  const config = {
    elementType: "switch",
    name: name,
    label: label,
    elementConfig: {
      type: "switch",
    },
    validation: {},
    disabled: disabled,
    value: value,
    valid: true,
    touched: false,
    errorMessage: "",
    style: style,
    labelPlacement: labelPlacement
  };

  return config;
}