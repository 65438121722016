import React, { Fragment, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import classes from './Alertas.module.css';
import { loadAlertasConfiguradas } from "../../../../store/slices/alertasSlice";
import { storageUsuarioId } from "../../../../assets/shared/sessionData";
import Spinner from "../../Spinner/Spinner";
import AlertaConfiguradaCard from "./AlertaConfiguradaCard";
import Dialog from "../../Dialog/Dialog";
import { closeAlertaModal } from "../../../../store/slices/alertasSlice";
import { Close } from "@mui/icons-material";
import AlertaConfiguradaDetalle from "./AlertaConfiguradaDetalle";
import AlertaConfiguradaEjecuciones from "./AlertaConfiguradaEjecuciones";
import AlertaConfiguradaEjecutar from "./AlertaConfiguradaEjecutar";
import AlertaConfiguradaEliminar from "./AlertaConfiguradaEliminar";

const AlertaConfiguradaList = (props) => {
  //#region Dispatches
  const dispatch = useDispatch();
  const loading = useSelector(state => state.alertas.alertasConfiguradas.loading);
  const estaCargado = useSelector(state => state.alertas.alertasConfiguradas.estaCargado);
  const datos = useSelector(state => state.alertas.alertasConfiguradas.alertas);
  const dialogState = useSelector(state => state.alertas.alertaModal);

  const empresasDelUsuario = useSelector(state => state.multiempresa.empresasDelUsuario.empresas);
  const usuariosDelCliente = useSelector(state => state.clientes.usuarios.usuarios);
  //#endregion

  const showModalContent = () => {
    if (dialogState.type === 'copy')
      return showModalCopyAlerta(dialogState.alerta);

    if (dialogState.type === 'update')
      return showModalUpdateAlerta(dialogState.alerta);

    if (dialogState.type === 'delete')
      return showModalDeleteAlerta(dialogState.alerta);

    if (dialogState.type === 'execute')
      return showModalExecuteAlerta(dialogState.alerta);

    if (dialogState.type === 'history')
      return showModalHistoryAlerta(dialogState.alerta);
  }

  const showModalCopyAlerta = (alerta) => {
    var copiaAlerta = { ...alerta };

    copiaAlerta.id = 0;
    copiaAlerta.tituloAlerta = `${alerta.tituloAlerta} - Copia`;

    if (!copiaAlerta.usuario) {
      var usuario = usuariosDelCliente.find((usu) => usu.id === storageUsuarioId());

      if (!usuario) {
        usuario = {
          id: storageUsuarioId()
        }
      }

      copiaAlerta.usuario = usuario;
    }

    return (
      <AlertaConfiguradaDetalle
        alerta={copiaAlerta}
        alertaId={alerta.id}
        empresas={empresasDelUsuario}
        usuarios={usuariosDelCliente}
        canEdit={true}
      />
    )
  }

  const showModalUpdateAlerta = (alerta) => {
    return (
      <AlertaConfiguradaDetalle
        alerta={alerta}
        alertaId={alerta.id}
        empresas={empresasDelUsuario}
        usuarios={usuariosDelCliente}
        canEdit={true}
      />
    )
  }

  const showModalHistoryAlerta = (alerta) => {
    return (
      <AlertaConfiguradaEjecuciones alerta={alerta} />
    )
  }

  const showModalExecuteAlerta = (alerta) => {
    return (
      <AlertaConfiguradaEjecutar alerta={alerta} />
    )
  }

  const showModalDeleteAlerta = (alerta) => {
    return (
      <AlertaConfiguradaEliminar alerta={alerta} />
    )
  }

  useEffect(() => {
    if (!estaCargado && !loading)
      dispatch(loadAlertasConfiguradas(storageUsuarioId()));
  }, [estaCargado, loading, dispatch]);

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.PanelContainer} display='flex' flexDirection='column'>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12} xl={7}>
            <Typography variant="h5">
              Alertas Configuradas
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} direction='row' justifyContent='flex-start'>
          {loading ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Spinner />
            </div>
          ) : (
            datos.map((alerta, index) => (
              <AlertaConfiguradaCard
                key={index}
                index={index}
                alerta={alerta}
              />
            ))
          )}
        </Grid>
      </Grid>
      <Dialog
        title={(
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">{dialogState.title}</Typography>
              <IconButton aria-label="close" onClick={() => dispatch(closeAlertaModal())} >
                <Close />
              </IconButton>
            </div>
            {dialogState && dialogState.subTitle && dialogState.subTitle !== ''
              && (<div className={classes.ModalSubtitleTexto}>
                <Typography variant="caption">
                  {dialogState.subTitle}
                </Typography>
              </div>
              )}
          </div>
        )}
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: dialogState.type === 'history'
            ? '10px 0px 0px'
            : undefined
        }}
        maxWidth={dialogState.maxWidth ? dialogState.maxWidth : 'md'}
        show={dialogState.show}
        body={
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            {showModalContent()}
          </div>
        }
        onClose={() => dispatch(closeAlertaModal())}
      />
    </Fragment>
  )
}

export default AlertaConfiguradaList;