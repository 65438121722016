import React from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch } from 'react-redux';
import { enviarNotificacionInforme } from "../../../../store/slices/informesSlice";

const TestInformes = () => {
  const dispatch = useDispatch();

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionInforme(20))}
          color='info'
          variant='contained'
          fullWidth={true}
          title="INFORME GASTOS POR CATEGORIA"
          style={{ paddingInline: '15px', color: 'white', fontSize: 'small' }} >
          Gastos por Categ.
        </Button>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionInforme(21))}
          color='info'
          variant='contained'
          fullWidth={true}
          title="INFORME SALDO ACREEDORES"
          style={{ paddingInline: '15px', color: 'white', fontSize: 'small' }} >
          Saldo Acreedores
        </Button>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionInforme(22))}
          color='info'
          variant='contained'
          fullWidth={true}
          title="INFORME SALDO DEUDORES"
          style={{ paddingInline: '15px', color: 'white', fontSize: 'small' }} >
          Saldo Deudores
        </Button>
      </Grid>
    </Grid>
  )
}

export default TestInformes;