import React, { Fragment, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import classes from './TipoAlertas.module.css';
import { storageUsuarioId } from "../../../../assets/shared/sessionData";
import Spinner from "../../Spinner/Spinner";
import Dialog from "../../Dialog/Dialog";
import { closeAlertaDisponibleModal, loadAlertasDisponibles, loadTiposUI } from "../../../../store/slices/tipoAlertaSlice";
import { Close } from "@mui/icons-material";
import TipoAlertasCategoriaCard from "./TipoAlertasCategoriaCard";
import TipoAlertasDetalle from "./TipoAlertasDetalle";
import TipoAlertasAyuda from "./TipoAlertasAyuda";
import AlertaConfiguradaDetalle from "../AlertasConfiguradas/AlertaConfiguradaDetalle";

const TipoAlertasList = (props) => {
  //#region Dispatches
  const dispatch = useDispatch();

  const loading = useSelector(state => state.tipoAlertas.alertasDisponibles.loading);
  const estaCargado = useSelector(state => state.tipoAlertas.alertasDisponibles.estaCargado);
  const alertas = useSelector(state => state.tipoAlertas.alertasDisponibles.alertas);
  const dialogState = useSelector(state => state.tipoAlertas.alertaDisponibleModal);

  const loadingTiposUI = useSelector(state => state.tipoAlertas.tiposUI.loading);
  const estaCargadoTiposUI = useSelector(state => state.tipoAlertas.tiposUI.estaCargado);

  const empresasDelUsuario = useSelector(state => state.multiempresa.empresasDelUsuario.empresas);
  const usuariosDelCliente = useSelector(state => state.clientes.usuarios.usuarios);
  //#endregion

  const showModalContent = () => {
    if (dialogState.type === 'ayuda')
      return showAyudaAlerta(dialogState.alerta);
    if (dialogState.type === 'configurar')
      return showConfigurarAlerta(dialogState.alerta);
    if (dialogState.type === 'editar')
      return showEditarAlerta(dialogState.alerta);
  }

  const showAyudaAlerta = (alerta) => {
    return (
      <TipoAlertasAyuda alerta={alerta} />
    )
  }

  const showConfigurarAlerta = (alerta) => {
    const newAlerta = {
      id: 0,
      idTipoAlerta: alerta.id,
      tipoAlerta: alerta,
      idEmpresa: '',
      responsable: '',
      tituloAlerta: '',
      descripCionAlerta: '',
      horarioEnvio: null,
      archivoAdjunto: '',
      notificarPorMail: true,
      habilitada: true,
      versionAlerta: 1,
      borrado: false,
      leida: false,
      configuracionesDefault: [],
      ultimaEjecucion: null,
      destinatariosEmail: [],
      destinatariosApp: []
    };
    return (
      <AlertaConfiguradaDetalle
        alerta={newAlerta}
        empresas={empresasDelUsuario}
        usuarios={usuariosDelCliente}
        canEdit={true}
      />
    )
  }

  const showEditarAlerta = (alerta) => {
    return (
      <TipoAlertasDetalle
        alerta={alerta}
        canEdit={true}
        onCloseModal={() => dispatch(closeAlertaDisponibleModal())}
      />
    )
  }

  const datosPorCategoria = (alertas) => {
    if (alertas && alertas.length > 0) {
      var items = [];
      const alertasPorCateg = Map.groupBy(alertas, alerta => { return alerta.categoria; });
      alertasPorCateg.forEach(element => {
        items.push({
          categoria: element[0] && element[0].categoria,
          alertas: element
        })
      });
      return items;
    }

    return [];
  }

  useEffect(() => {
    if (!estaCargado && !loading)
      dispatch(loadAlertasDisponibles(storageUsuarioId()));
  }, [estaCargado, loading, dispatch]);

  useEffect(() => {
    if (!estaCargadoTiposUI && !loadingTiposUI)
      dispatch(loadTiposUI());
  }, [estaCargadoTiposUI, loadingTiposUI, dispatch]);

  return (
    <Fragment>
      <Grid container spacing={1} className={classes.PanelContainer} justifyContent='flex-start' alignContent='flex-start'>
        <Grid item xs={12} sx={{ marginBottom: '7px' }}>
          <Typography variant="h5">
            Alertas Disponibles
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} direction='row' justifyContent='space-evenly'>
          {loading ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Spinner />
            </div>
          ) : (
            <Fragment>
              {alertas && datosPorCategoria(alertas).map((item, index) => {
                return (
                  <TipoAlertasCategoriaCard
                    key={index}
                    index={index}
                    categoria={item.categoria}
                    alertas={item.alertas}
                  />
                )
              })}
            </Fragment>
          )}
        </Grid>
      </Grid>
      <Dialog
        title=
        {
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">{dialogState.title}</Typography>
              <IconButton aria-label="close" onClick={() => dispatch(closeAlertaDisponibleModal())} >
                <Close />
              </IconButton>
            </div>
            {dialogState && dialogState.subTitle && dialogState.subTitle !== ''
              && (<div className={classes.ModalSubtitleTexto}>
                <Typography variant="caption">
                  {dialogState.subTitle}
                </Typography>
              </div>
              )}
          </div>
        }
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: dialogState.type === 'history'
            ? '10px 0px 0px'
            : dialogState.type !== 'execute' ? '20px 40px 40px 40px' : undefined
        }}
        maxWidth={dialogState.maxWidth ? dialogState.maxWidth : 'md'}
        show={dialogState.show}
        body={
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            {showModalContent()}
          </div>
        }
        onClose={() => dispatch(closeAlertaDisponibleModal())}
      />
    </Fragment>
  )
}

export default TipoAlertasList;