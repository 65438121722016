import React from "react";
import classes from './InformeDisponible.module.css';
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { HelpOutline, Add } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { openAyudaInformeDisponibleModal, openConfigurarInformeDisponibleModal } from "../../../../store/slices/tipoInformeSlice";

const InformeDisponibleCard = (props) => {
  const dispatch = useDispatch();
  const { informe } = props;
  const puedeConfigurar = informe && informe.cantidadCreacionesInforme < informe.cantidadConfiguracionesInforme;

  return (
    <Grid item xs={12} lg={6} container style={{ maxWidth: '95%' }}>
      <Grid item xs={12} container className={classes.ItemCard}>
        <Grid item xs={7} lg={9} container spacing={1} flexDirection='row' justifyContent='center' alignContent='center' >
          <Grid item xs={12}>
            <Typography>
              <strong>Título del informe: </strong> <span>{informe && informe.tituloGauss}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={3} container spacing={1} flexDirection='row' justifyContent='center' alignContent='center' >
          <Grid item xs={2}>
            <Tooltip title='Ayuda'>
              <IconButton onClick={() => dispatch(openAyudaInformeDisponibleModal(informe))}>
                <HelpOutline htmlColor='#2b2bef' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={puedeConfigurar ? 'Configurar informe' : 'Se ha alcanzado el número máximo de configuraciones permitidas' }>
              <IconButton onClick={() => puedeConfigurar ? dispatch(openConfigurarInformeDisponibleModal(informe)) : undefined }>
                <Add htmlColor='#2b2bef' />
              </IconButton>
            </Tooltip>
          </Grid>   
          <Grid item xs={6} style={{ textAlign: 'center', paddingTop: '15px' }}>
            <Tooltip title="Cant. de informes configurados">
              <strong style={{ fontSize: 'large' }}>{informe.cantidadCreacionesInforme}</strong>
            </Tooltip> / 
            <Tooltip title="Cant. máxima de informes a configurar">
              <strong style={{ fontSize: 'large' }}>{informe.cantidadConfiguracionesInforme}</strong>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InformeDisponibleCard;