import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './Alertas.module.css';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  NotificationsActiveOutlined, NotificationsOffOutlined, EditOutlined, HistoryEduOutlined,
  ContentCopyOutlined, PlayCircleOutlined, DeleteOutlineOutlined
} from '@mui/icons-material';
import {
  habilitarAlerta, deshabilitarAlerta, loadAlertasConfiguradas,
  openCopiarAlertaModal, openActualizarAlertaModal, openHistorialAlertaModal,
  openEjecutarAlertaModal, clearEjecutarAlerta, openEliminarAlertaModal
} from '../../../../store/slices/alertasSlice';
import { storageUsuarioId } from '../../../../assets/shared/sessionData';
import moment from 'moment';

const AlertaConfiguradaCard = (props) => {
  const dispatch = useDispatch();
  const { alerta } = props;

  const onHabilitarAlerta = (alerta) => {
    dispatch(habilitarAlerta(alerta.id));
    dispatch(loadAlertasConfiguradas(storageUsuarioId()));
  }

  const onDeshabilitarAlerta = (alerta) => {
    dispatch(deshabilitarAlerta(alerta.id));
    dispatch(loadAlertasConfiguradas(storageUsuarioId()));
  }

  const onEjecutarAlerta = (alerta) => {
    dispatch(clearEjecutarAlerta());
    dispatch(openEjecutarAlertaModal(alerta));
  }

  return (
    <Grid item xs={12} lg={6} container style={{ maxWidth: '95%' }}>
      <Grid item xs={12} container className={classes.ItemCard}>
        <Grid item xs={8} container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              <strong style={{ fontSize: 'small' }}>Título de la alerta: </strong> <span>{alerta && alerta.tituloAlerta}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Empresa: </strong><br />
                <span>{alerta && alerta.empresa && alerta.empresa.nombre}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Propietario: </strong><br />
                <span>{alerta && alerta.usuario && alerta.usuario.nombre}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Última ejecución: </strong><br />
                <span>{alerta && alerta.ultimaEjecucion ? moment(alerta.ultimaEjecucion).format('HH:mm DD/MM/yyyy') : ''}</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container spacing={1} justifyContent='flex-end' alignContent='center'>
          <Grid item xs={2}>
            {alerta.habilitada ? (
              <Tooltip title="Deshabilitar alerta">
                <IconButton onClick={() => onDeshabilitarAlerta(alerta)}>
                  <NotificationsActiveOutlined htmlColor='#4fd14f' />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Habilitar alerta">
                <IconButton onClick={() => onHabilitarAlerta(alerta)}>
                  <NotificationsOffOutlined htmlColor='#f75d5d' />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Editar alerta">
              <IconButton onClick={() => dispatch(openActualizarAlertaModal(alerta))}>
                <EditOutlined htmlColor='#2b2bef' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Eliminar alerta">
              <IconButton onClick={() => dispatch(openEliminarAlertaModal(alerta))}>
                <DeleteOutlineOutlined htmlColor='#2b2bef' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Historial de ejecuciones">
              <IconButton onClick={() => dispatch(openHistorialAlertaModal(alerta))}>
                <HistoryEduOutlined htmlColor='#2b2bef' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Copiar alerta">
              <IconButton onClick={() => dispatch(openCopiarAlertaModal(alerta))}>
                <ContentCopyOutlined htmlColor='#2b2bef' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Ejecutar alerta manualmente">
              <IconButton onClick={() => onEjecutarAlerta(alerta)}>
                <PlayCircleOutlined htmlColor='#2b2bef' />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AlertaConfiguradaCard;